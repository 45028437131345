import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


function Planning() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const response = await axios.get(`${apiBaseUrl}/api/planning`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEvents(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des événements', error);
    }
  };

  // Group events by date
  const eventsByDate = events.reduce((acc, event) => {
    if (!acc[event.date]) acc[event.date] = [];
    acc[event.date].push(event);
    return acc;
  }, {});

  // Generate a list of dates for the current week
  const getWeekDates = () => {
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
    return Array.from({ length: 7 }, (_, i) => {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);
      return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    });
  };

  const weekDates = getWeekDates();

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Planning de la semaine</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-4">
        {weekDates.map((date, index) => (
          <div key={index} className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className={`p-2 text-center cursor-pointer transition-colors duration-300 ${eventsByDate[date] ? 'bg-red-100 hover:bg-red-200' : 'bg-red-200 hover:bg-red-300'}`}>
              <div className="font-bold text-base md:text-lg text-red-900">{new Date(date).toLocaleDateString('fr-FR', { weekday: 'short' })}</div>
              <div className="text-xs md:text-sm text-gray-600">{new Date(date).toLocaleDateString('fr-FR')}</div>
            </div>
            <div className="p-2 space-y-2">
              {eventsByDate[date] ? (
                eventsByDate[date].map((event, idx) => (
                  <div key={idx} className="p-2 rounded relative bg-red-50">
                    <div className="font-semibold text-sm md:text-base text-red-900">{event.title}</div>
                    <div className="text-xs md:text-sm text-gray-600">{event.start_time} - {event.end_time}</div>
                    <button className="absolute top-1 right-1 text-red-600 hover:text-red-800">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                ))
              ) : (
                <div className="text-sm text-gray-500 italic">Aucun événement</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Planning;