import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

console.log('API Base URL:', apiBaseUrl); // Vérifiez si cette ligne affiche la bonne URL

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchUnreadNotifications = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const response = await axios.get(`${apiBaseUrl}/api/notifications`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const unreadNotifications = response.data.filter(notification => !notification.is_read);
      setUnreadCount(unreadNotifications.length);
    } catch (error) {
      console.error('Erreur lors de la récupération des notifications non lues', error);
    }
  };

  useEffect(() => {
    fetchUnreadNotifications();
  }, []);

  return (
    <NotificationContext.Provider value={{ unreadCount, fetchUnreadNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};