import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


function Dashboard() {
  const [userInfo, setUserInfo] = useState({});
  
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        const response = await axios.get(`${apiBaseUrl}/api/dashboard`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserInfo(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des informations utilisateur', error);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <div className="container mx-auto p-4 flex-1">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-center text-red-900">Bienvenue, {userInfo.username} !</h1>
      <div className="flex justify-center mb-4">
        <h1 className="text-xl md:text-2xl font-semibold text-red-900">Rôle: {userInfo.role}</h1>
      </div>
      <div className="bg-red-100 p-4 rounded-lg mb-4 flex justify-between items-center">
        <div>
          <h2 className="text-lg font-semibold text-red-900">Heures cette semaine</h2>
          <p className="text-2xl font-bold text-red-700">{userInfo.weekly_hours} h</p>
        </div>
        <div>
          <h2 className="text-lg font-semibold text-red-900">Heures en Septembre</h2>
          <p className="text-2xl font-bold text-red-700">{userInfo.monthly_hours} h</p>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;