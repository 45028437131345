import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { NotificationContext } from '../context/NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const { isAuthenticated, logout, userRole } = useContext(AuthContext);
  const { unreadCount } = useContext(NotificationContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header className="bg-red-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <span className="text-xl font-bold">K-Planning</span>
        </div>
        <nav>
          {!isAuthenticated ? (
            <>
              <Link to="/login" className="px-3 py-2 rounded hover:bg-red-700">Connexion</Link>
              <Link to="/register" className="px-3 py-2 rounded hover:bg-red-700">Inscription</Link>
            </>
          ) : (
            <>
              <Link to="/" className="px-3 py-2 rounded hover:bg-red-700">Tableau de bord</Link>
              <Link to="/planning" className="px-3 py-2 rounded hover:bg-red-700">Planning</Link>
              <Link to="/calendar" className="px-3 py-2 rounded hover:bg-red-700">Calendrier</Link>
              <Link to="/badgeuse" className="px-3 py-2 rounded hover:bg-red-700">Badgeuse</Link>

              {userRole === 'admin' && (
                <Link to="/admin" className="px-3 py-2 rounded hover:bg-red-700">Administration</Link>
              )}

              <Link to="/notifications" className="relative px-3 py-2 rounded hover:bg-red-700">
                <FontAwesomeIcon icon={faBell} />
                {unreadCount > 0 && (
                  <span className="absolute top-0 right-0 inline-block w-6 h-6 bg-blue-600 text-white text-sm font-bold rounded-full text-center">
                    {unreadCount}
                  </span>
                )}
              </Link>

              <button onClick={handleLogout} className="px-3 py-2 rounded hover:bg-red-700">
                Deconnexion
              </button>
            </>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;