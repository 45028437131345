import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


function PlanningManagement() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        const response = await axios.get(`${apiBaseUrl}/api/admin/events`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEvents(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des événements', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="p-8 ml-64">
      <h3 className="text-lg font-semibold mb-2">Gestion des Plannings</h3>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2">ID</th>
            <th className="py-2">Titre</th>
            <th className="py-2">Date</th>
            <th className="py-2">Heure de début</th>
            <th className="py-2">Heure de fin</th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (
            <tr key={event.id}>
              <td className="border px-4 py-2">{event.id}</td>
              <td className="border px-4 py-2">{event.title}</td>
              <td className="border px-4 py-2">{event.date}</td>
              <td className="border px-4 py-2">{event.start_time}</td>
              <td className="border px-4 py-2">{event.end_time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PlanningManagement;