import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import logo from '../img/logo.png'; // Importation de l'image

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiBaseUrl}/login`, { email, password });
      login(response.data.token, rememberMe);
      alert('Connexion réussie');
      navigate('/');
    } catch (error) {
      alert('Erreur lors de la connexion');
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-zinc-50">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img src={logo} alt="K-Planning logo" className="h-12 w-12" />
        </div>
        <h2 className="text-center text-2xl font-bold text-zinc-900">Se connecter</h2>
        <p className="text-center text-zinc-600 mb-8">
          Connectez-vous avec votre e-mail et votre mot de passe K-Planning.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="sr-only">E-mail</label>
            <input
              type="email"
              id="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg bg-zinc-50 border-zinc-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="password" className="sr-only">Mot de passe</label>
            <input
              type="password"
              id="password"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg bg-zinc-50 border-zinc-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
              className="h-4 w-4 text-blue-600 border-zinc-300 rounded focus:ring-red-900"
            />
            <label htmlFor="rememberMe" className="ml-2 text-zinc-600">Rester connecté(e)</label>
          </div>
          <button
            type="submit"
            className="w-full py-2 bg-red-900 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-900"
          >
            Se connecter
          </button>
        </form>
        <p className="mt-6 text-center text-zinc-600">
          Vous souhaitez utiliser K-Planning?{' '}
          <a href="/register" className="text-red-900">S'inscrire</a>
        </p>
      </div>
    </div>
  );
}

export default Login;