import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { NotificationContext } from '../context/NotificationContext';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const { fetchUnreadNotifications } = useContext(NotificationContext);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        const response = await axios.get(`${apiBaseUrl}/api/notifications`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setNotifications(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des notifications', error);
      }
    };

    fetchNotifications();
  }, []);

  const markAsRead = async (id) => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      await axios.post(`${apiBaseUrl}/api/notifications/${id}/read`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNotifications(notifications.map(notification =>
        notification.id === id ? { ...notification, is_read: 1 } : notification
      ));
      fetchUnreadNotifications(); // Rafraîchir les notifications non lues
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la notification', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Notifications</h2>
      <ul>
        {notifications.map(notification => (
          <li key={notification.id} className={`p-4 mb-2 rounded ${notification.is_read ? 'bg-gray-200' : 'bg-blue-100'}`}>
            <p>{notification.message}</p>
            {!notification.is_read && (
              <button onClick={() => markAsRead(notification.id)} className="text-sm text-blue-500 hover:text-blue-700">
                Marquer comme lue
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Notifications;