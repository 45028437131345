import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import logo from '../img/logo.png'; // Assurez-vous que le chemin est correct

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiBaseUrl}/register`, { username, email, password });
      login(response.data.token, true); // Connecte l'utilisateur après l'inscription
      alert('Inscription réussie');
      navigate('/'); // Redirige vers le tableau de bord ou la page d'accueil
    } catch (error) {
      alert('Erreur lors de l\'inscription');
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-zinc-50">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img src={logo} alt="K-Planning logo" className="h-12 w-12" />
        </div>
        <h2 className="text-center text-2xl font-bold text-zinc-900">S'inscrire</h2>
        <p className="text-center text-zinc-600 mb-8">Créez votre compte K-Planning.</p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="username" className="sr-only">Nom complet</label>
            <input
              type="text"
              id="username"
              placeholder="Nom complet"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg bg-zinc-50 border-zinc-300 focus:outline-none focus:ring-2 focus:ring-red-900"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="sr-only">E-mail</label>
            <input
              type="email"
              id="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg bg-zinc-50 border-zinc-300 focus:outline-none focus:ring-2 focus:ring-red-900"
              required
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="password" className="sr-only">Mot de passe</label>
            <input
              type="password"
              id="password"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg bg-zinc-50 border-zinc-300 focus:outline-none focus:ring-2 focus:ring-red-900"
              required
            />
            {/* Optionnel : Ajouter un bouton pour afficher/masquer le mot de passe */}
          </div>
          <p className="text-sm text-zinc-600 mb-4">Doit comporter au moins 8 caractères</p>
          <button
            type="submit"
            className="w-full py-2 bg-red-900 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-900"
          >
            S'inscrire
          </button>
        </form>
        <p className="mt-6 text-center text-zinc-600">
          Vous avez déjà un compte?{' '}
          <a href="/login" className="text-red-900">Se connecter</a>
        </p>
      </div>
    </div>
  );
}

export default Register;