import React, { useState } from 'react';
import UserManagement from './UserManagement'; // Import the UserManagement component
import PlanningManagement from './PlanningManagement'; // Import the PlanningManagement component
import BroadcastNotification from './BroadcastNotification'; // Import the BroadcastNotification component

function Admin() {
  const [selectedSection, setSelectedSection] = useState('users'); // Default section

  const renderSection = () => {
    switch (selectedSection) {
      case 'users':
        return <UserManagement />;
      case 'planning':
        return <PlanningManagement />;
      case 'broadcast':
        return <BroadcastNotification />;
      default:
        return <UserManagement />;
    }
  };

  return (
    <div className="flex">
      {/* Sidebar for navigation */}
      <div className="bg-gray-800 text-white w-64 h-full fixed">
        <div className="p-4">
          <h2 className="text-lg font-bold">Administration</h2>
          <ul className="mt-4">
            <li className="mb-2">
              <button
                onClick={() => setSelectedSection('users')}
                className={`block w-full text-left px-4 py-2 hover:bg-gray-700 rounded ${
                  selectedSection === 'users' ? 'bg-gray-700' : ''
                }`}
              >
                Gestion des Utilisateurs
              </button>
            </li>
            <li className="mb-2">
              <button
                onClick={() => setSelectedSection('planning')}
                className={`block w-full text-left px-4 py-2 hover:bg-gray-700 rounded ${
                  selectedSection === 'planning' ? 'bg-gray-700' : ''
                }`}
              >
                Gestion des Plannings
              </button>
            </li>
            <li className="mb-2">
              <button
                onClick={() => setSelectedSection('broadcast')}
                className={`block w-full text-left px-4 py-2 hover:bg-gray-700 rounded ${
                  selectedSection === 'broadcast' ? 'bg-gray-700' : ''
                }`}
              >
                Envoyer une Notification
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Main content area */}
      <div className="flex-grow ml-64 p-8">
        <h2 className="text-2xl font-bold mb-6">Administration</h2>
        {renderSection()}
      </div>
    </div>
  );
}

export default Admin;