import React, { useState } from 'react';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


function BroadcastNotification() {
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const sendNotification = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      await axios.post(`${apiBaseUrl}/api/notifications/broadcast`, { message }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStatus('Notification envoyée avec succès');
      setMessage('');
    } catch (error) {
      setStatus("Erreur lors de l'envoi de la notification");
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Envoyer une Notification à Tous les Utilisateurs</h2>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Entrez votre message ici..."
        className="w-full p-2 border rounded mb-4"
      />
      <button onClick={sendNotification} className="bg-blue-600 text-white px-4 py-2 rounded">
        Envoyer
      </button>
      {status && <p className="mt-4">{status}</p>}
    </div>
  );
}

export default BroadcastNotification;